/* SkillsSection.css */

.skills-section {
    text-align: center;
    margin-bottom: 4rem;
}

.skills-heading {
    font-family: 'Raleway', sans-serif;
    font-size: 1.5rem;
    font-weight: bolder;
    color: #000000;
    margin-bottom: 1rem;
}

.skills-description {
    font-family: 'Libre Baskerville', sans-serif;
    font-size: 1rem;
    font-weight: bolder;
    color: #666;
    margin-bottom: -1px;
    margin-top: 1rem;
}


/* Adjust the columns for different screen sizes */

@media only screen and (min-width: 600px) {
    .skills-section {
        margin-top: -4rem;
    }
    .skills-heading {
        font-size: 2.5rem;
        /* Adjust as needed */
    }
    .skills-description {
        font-size: 1.2rem;
        /* Adjust as needed */
    }
    .skills-section .simple-grid {
        margin-left: 21%;
        margin-right: 10%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1px;
    }
}

@media only screen and (max-width: 599px) {
    .skills-section .simple-grid {
        display: block;
    }
}