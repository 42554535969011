/* Home.css */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    background-color: cornsilk;
    margin-bottom: 10%;
    margin-top: 2%;
}

.main-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 650px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
}

.photo-card {
    width: 400px;
    height: 400px;
    margin-bottom: 22px;
    margin-top: 5px;
}

.photo {
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-color: rgb(255, 255, 255);
    /* Change this color as needed */
}

.profile-info {
    text-align: top;
}


/* Home.css */

.name {
    font-family: 'Raleway', sans-serif;
    /* Set the Raleway font */
    font-size: 40px;
    /* Set the font size to 36px */
    font-weight: 700;
    /* Set the font weight to 700 (bold) */
    color: #333;
    /* Set the desired text color */
    margin-bottom: 10px;
    /* Add margin space at the bottom */
    text-align: center;
    /* Center-align the text */
}


/* Home.css */

.profession {
    font-family: 'Raleway', serif;
    /* Set the Libre Baskerville font */
    font-size: 15px;
    /* Adjust the font size as needed */
    font-weight: bold;
    font-style: italic;
    /* Optionally, set the font weight */
    color: #333;
    /* Set the desired text color */
    margin-top: -10px;
    /* Add margin space at the top */
    text-align: center;
    /* Center-align the text */
    position: relative;
}


/* Additional styling if needed */

.description {
    color: #000000;
    /* Set the desired text color */
    font-family: 'Libre Baskerville', serif;
    /* Set the Libre Baskerville font */
    font-size: 15px;
    /* Set the font size to 24px */
    /* Set the font style to italic */
    line-height: 30px;
    /* Set the line height to 32px */
    grid-area: auto;
    /* Set the grid area to auto */
    text-align: left;
    margin-top: 40px;
    /* Center-align the text */
    margin-bottom: 20px;
}

.scroll-indicator-box {
    /* Add your styles for the scroll indicator box */
}

@media only screen and (max-width: 765px) {
    .photo-card {
        margin-left: 25%;
        width: 100%;
        height: 100%;
        margin-bottom: 141%;
        margin-top: -51%;
    }
    .main-card {
        margin-left: -73%;
        display: flex;
        flex-direction: column;
        margin-top: 50%;
        /* margin-top: 82%; */
        text-align: center;
        width: fit-content;
        padding: 43px;
        border: 1px solid #ccc;
        border-radius: 8px;
        background-color: #fff;
    }
}