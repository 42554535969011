    /* projects.css */
    
    .project-container {
        margin: 0 auto;
        max-width: 800px;
        padding: 20px;
        margin-bottom: 2%;
    }
    
    .project-card {
        border: 1px solid #ccc;
        border-radius: 8px;
        overflow: hidden;
        padding: 8px;
        width: 370px;
        margin: 0 auto;
        text-align: center;
        transition: transform 0.3s ease-in-out;
        margin-bottom: 20px;
    }
    
    .project-card:hover {
        transform: scale(1.05);
    }
    
    .project-image {
        width: 100%;
        height: auto;
        border-radius: 8px;
        margin-bottom: 8px;
    }
    
    .project-title {
        font-family: 'Raleway', sans-serif;
        font-size: 24px;
        font-weight: bold;
        color: #333;
        margin-bottom: 4px;
        text-decoration: none;
    }
    
    .project-details {
        font-family: 'Libre Baskerville', sans-serif;
        font-size: 15px;
        font-style: italic;
        font-weight: bolder;
        color: #666;
    }
    
    .project-stack {
        font-family: 'Raleway', sans-serif;
        font-size: 14px;
        font-weight: bolder;
        color: #333;
        margin-bottom: 4px;
        text-decoration: none;
    }
    
    .projects-heading {
        font-family: 'Raleway', sans-serif;
        font-size: 50px;
        font-weight: bolder;
        grid-area: auto;
        line-height: 20px;
        margin-bottom: 7%;
    }
    /* Styling for the slider item */
    
    .slider-item {
        padding: 0 8px;
    }
    /* projects.css */
    /* Slider container */
    
    .slick-slider {
        position: relative;
        margin: 0 auto;
    }
    /* Individual slide item */
    
    .slick-slide {
        padding: 0 8px;
    }
    /* Dots style */
    
    .slick-dots {
        position: absolute;
        bottom: 10px;
        list-style: none;
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0;
    }
    
    .slick-dots li {
        margin: 0 4px;
    }
    
    .slick-dots button {
        font-size: 10px;
        line-height: 1;
        color: #fff;
        background-color: #4a5568;
        border: none;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
    }
    
    .slick-dots button:hover {
        background-color: #2d3748;
    }
    
    .slick-prev,
    .slick-next {
        font-size: 0;
        /* Set font-size to 0 to hide the text */
        color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        cursor: pointer;
        background-color: #4a5568;
        border: none;
        border-radius: 100%;
        /* Set border-radius to 0 to remove the circle */
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .slick-prev::before,
    .slick-next::before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
    }
    
    .slick-prev::before {
        content: '\f104';
        /* Font Awesome left arrow icon */
    }
    
    .slick-next::before {
        content: '\f105';
        /* Font Awesome right arrow icon */
    }
    
    .slick-prev {
        left: 10px;
        margin-left: -40px;
        /* Adjust the margin as needed */
    }
    
    .slick-next {
        right: 10px;
        margin-right: -40px;
        /* Adjust the margin as needed */
    }
    /* Arrows hover effect */
    
    .slick-prev:hover,
    .slick-next:hover {
        background-color: #2d3748;
    }
    /* Spacing between arrows and project cards */
    
    .slick-prev,
    .slick-next,
    .slick-slider {
        margin-bottom: 60px;
        /* Adjust the margin as needed */
    }
    
    .tab-button {
        padding: 10px 20px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        /* Set the background color to transparent */
        transition: color 0.3s ease-in-out;
        font-family: 'Libre Baskerville', sans-serif;
        font-size: 20px;
        font-weight: bolder;
        color: #666;
        margin-bottom: -1px;
        /* Set the desired text color */
        margin-top: -10px;
        /* Add margin space at the top */
        text-align: center;
        /* Center-align the text */
        position: relative;
    }
    
    .tab-button:hover {
        color: #f69708de;
        /* Change the text color on hover */
    }
    
    .tab-panel {
        padding: 20px;
    }
    /* Styling for the active tab */
    
    .tab-button[aria-selected="true"] {
        color: #000000;
        border: #333;
        border-radius: 10%;
        /* Change the text color for the active tab */
        box-shadow: 0 2px 4px rgba(143, 141, 141, 0.2);
        /* Add box shadow to the active tab */
    }
    
    @media only screen and (max-width: 765px) {
        .project-card {
            border: 1px solid #ccc;
            border-radius: 8px;
            overflow: hidden;
            padding: -5px;
            width: 250px;
            margin: 0 auto;
            text-align: center;
            transition: transform 0.3s ease-in-out;
            margin-bottom: 20px;
            margin-right: 10%;
        }
        .project-container {
            /* margin: 0 auto; */
            max-width: 834px;
            padding: -10px;
            margin-bottom: 2%;
            MARGIN-LEFT: 10%;
            WIDTH: fit-content;
        }
        .projects-heading {
            font-family: 'Raleway', sans-serif;
            font-size: 20px;
            font-weight: bolder;
            grid-area: auto;
            line-height: 20px;
            margin-bottom: 7%;
            justify-content: left;
            margin-left: -65.5%;
        }
        .skills-description {
            font-family: 'Libre Baskerville', sans-serif;
            font-size: 10px;
            font-weight: bolder;
            color: #666;
            margin-bottom: -1px;
            margin-top: 1rem;
            margin-left: 8%;
            margin-right: 38%;
        }
    }