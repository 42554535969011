/* AppStyles.css */

.skill_css {
    margin-top: -211px;
    margin-left: 500x;
    display: grid;
    grid-gap: 4px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 200px;
}