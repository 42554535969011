.intro-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    background-color: cornsilk;
    margin-bottom: -16%;
    margin-top: 10%;
    margin-left: 4%;
}

.greeting {
    font-family: 'Raleway', sans-serif;
    font-size: 50px;
    font-weight: 700;
    grid-area: auto;
    line-height: 40px;
    text-align: left;
    margin-left: 1%;
}

.intro-text {
    font-family: 'Libre Baskerville', sans-serif;
    font-size: 20px;
    font-weight: 200;
    grid-area: auto;
    line-height: 32px;
    margin-top: 3%;
    margin-bottom: 0px;
    margin-left: 1%;
    text-align: left;
    justify-content: left;
    margin-right: 50%;
}

body {
    background: cornsilk;
    margin: 0;
}

.scroll-indicator-box {
    text-align: center;
    margin-top: 30px;
}

.arrows {
    width: 60px;
    height: 72px;
    position: absolute;
    align-items: center;
    margin-bottom: 13%;
}

.arrows path {
    stroke: #090909;
    fill: transparent;
    stroke-width: 1px;
    animation: arrow 2s infinite;
}

@keyframes arrow {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.arrows path.a1 {
    animation-delay: -1s;
}

.arrows path.a2 {
    animation-delay: -0.5s;
}

.arrows path.a3 {
    animation-delay: 0s;
}

.fade-in {
    animation: fadeIn 2s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* Media query for mobile view */

@media only screen and (max-width: 765px) {
    .greeting {
        font-size: 30px;
        line-height: 30px;
        text-align: center;
        margin-top: 80%;
        /* Adjust this value as needed for vertical centering */
    }
    .intro-text {
        font-size: 15px;
        line-height: 24px;
        margin-left: 2em;
        margin-right: 2em;
    }
    .arrows {
        margin-top: -19%;
        width: 44px;
        height: 72px;
        position: absolute;
        align-items: center;
        margin-bottom: 37%;
        margin-left: 40%;
    }
    .intro-container {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 50%;
        margin-top: 28%;
    }
}

@media only screen and (max-width: 465px) {
    .greeting {
        font-size: 30px;
        line-height: 30px;
        text-align: center;
        margin-top: 130%;
        /* Adjust this value as needed for vertical centering */
    }
    .intro-text {
        font-size: 15px;
        line-height: 24px;
        margin-left: 2em;
        margin-right: 2em;
    }
    .arrows {
        margin-top: -19%;
        width: 44px;
        height: 72px;
        position: absolute;
        align-items: center;
        margin-bottom: 37%;
        margin-left: 40%;
    }
    .intro-container {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 50%;
        margin-top: 28%;
    }
}